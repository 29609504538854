import InputSection from "./InputSection/inputSection";
const ContactUsSection = () => {

    return (
        <section className="contact section contact-faq">
            <div className="container">
                <div className="row">
                    <div className="contactForm col-lg-9 col-md-9 col-12">
                        <InputSection/>
                    </div> 
                    <div className="col-lg-3 col-md-3 col-12 contact_info">
                        <h5 className="contact_info-header">Develop & implement project?</h5>
                        <ul className="contact-info">
                            <li className="contact-info_group">
                                <span className="name">Address</span>
                                <span className="content">Mahadevsthan Marga, Koteshwor</span>
                            </li>
                            <li className="contact-info_group">
                                <span className="name">Email</span>
                                <span className="content d-inline-flex flex-column">
                                    <a className="link" href="mailto:mebikalbinodadhikari@gmail.com">mebikalbinodadhikari@gmail.com</a>
                                </span>
                            </li>
                            <li className="contact-info_group">
                                <span className="name">Phone</span>
                                <span className="content d-inline-flex flex-column">
                                    <a className="link" href="tel:9809107997">9809107997</a>
                                </span>
                            </li>
                        </ul>
                        <ul className="mediaLink displayFlex  justify-content-start">
                            <li className="mediaLink_item">
                                <a className="mediaLink_item-link" href="https://www.facebook.com/bikalbinodadhikari/" target="_blank" rel="noopener noreferrer">
                                    <i className="icon-facebook"></i>
                                </a>
                            </li>
                            <li className="mediaLink_item">
                                <a className="mediaLink_item-link" href="https://www.instagram.com/bikalbinodadhikari/?hl=en" target="_blank" rel="noopener noreferrer">
                                    <i className="icon-instagram"></i>
                                </a>
                            </li>
                            <li className="mediaLink_item">
                            <a className="mediaLink_item-link" href="https://np.linkedin.com/in/bikal-binod-adhikari">
                                <i className="fab fa-linkedin"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ContactUsSection;