import "./cover.css";
const Cover = ({ title }) => {
    return (
        <div className="header_extension primary-bg">
            <div className="container">
                <div className="section_header">
                <li className="breadcrumbs_item breadcrumbs_item--current">
                    <span className="lineTitle lineTitle--extended">{title}</span>
                  </li>  
                </div>
            </div>
            <div className="imgContainer">
               

                <img src={require('../../../Images/Bikal Binod Adhikari  enjoying in bridge.JPG')}
                     alt="Bikal Binod Adhikari  enjoying in bridge"
                     className="designImage" 
                   
                   />
            </div>
        </div>
    )
}
export default Cover;