import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../ConfigurationSystem/firebase";
import { getDoc, doc } from "firebase/firestore";

import Footer from "../CommonComponents/Footer/footer";
import Header from "../CommonComponents/Header/header";
import Cover from "../CommonComponents/Cover/cover";
import OptionSection from "../Blogs/BlogComponents/OptionSection/optionSection";
import InputSection from "../FAQ/FAQComponents/ContactUsSection/InputSection/inputSection";

import parse from "html-react-parser";

import "./singleBlog.css";

const SingleBlog = () => {
    const docId = useParams().id;

    const [data, setData] = useState([]);

    useEffect(() => {
        const getIndvContent = async () => {
            window.scroll({top:0 , behavior:"instant"});
            const contents = await getDoc(doc(db, 'Blogs', docId));
            setData(contents.data());
        }
        getIndvContent();
    }, [])

    return (
        <>
            <Header />
            <Cover title={data.Title} />
            <div className="container singlePostContainer">
                <div className="row">
                    <div className="col-lg-8 col-12 singleBlogContainer">
                        <img src={`https://firebasestorage.googleapis.com/v0/b/bikal-cv.appspot.com/o/BlogsCovers%2F${data.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23)`} className="img-fluid image-singleBlog"/>
                        <p className="article-singleBlog"> {data.Article && parse(data.Article)} </p> 
                        <InputSection/>
                    </div>
                    <div className="col-lg-4 col-12">
                        <OptionSection data={data}/>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    ) 
}
export default SingleBlog;