import { useEffect } from "react";

import Cover from "../CommonComponents/Cover/cover";
import Header from "../CommonComponents/Header/header";
import Footer from "../CommonComponents/Footer/footer";
import ServicesMini from "./ServicesComponents/ServicesMini/servicesMini";
import NumbersContainer from "./ServicesComponents/NumbersContainer/numbersContainer";
import SkillsSection from "./ServicesComponents/SkillsSection/skillsSection";
import GallerySection from "../About/AboutComponents/GallerySection/gallerySection";
import ContactUsSection from "../FAQ/FAQComponents/ContactUsSection/contactUsSection";

const Services=()=>{
    
    useEffect(()=>{
        window.scroll({top:0 , behavior:"instant"});
    },[])

    return(
        <>
            <Header />
            <main>
                <Cover title={"Services"} />
                <ServicesMini/>
                <NumbersContainer/>
                <SkillsSection/>
                
                <ContactUsSection/>
                <Footer />
            </main>
        </>
    )
}
export default Services;