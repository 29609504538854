import { useState , useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../ConfigurationSystem/firebase";
import { getDoc, doc } from "firebase/firestore";

import Header from "../CommonComponents/Header/header";
import Footer from "../CommonComponents/Footer/footer";
import Cover from "../CommonComponents/Cover/cover";

import ProcessSection from "../Home/HomeComponents/ProcessSection/processSection";
import ServicesMini from "../Services/ServicesComponents/ServicesMini/servicesMini";
import FeedbackSection from "../Home/HomeComponents/FeedbackSection/feedbackSection";
import SingleServiceContent from "./SingleServiceComponents/singleServiceContent";
import ContactUsSection from "../FAQ/FAQComponents/ContactUsSection/contactUsSection";

import "./singleService.css";

const SingleService = ({collectionName}) => {
    const docId=useParams().id;
    const [data, setData] = useState([]);

    useEffect(() => {
        const getIndvContent = async () => {
            window.scrollTo({top:0 , behavior:"instant"});
            const contents = await getDoc(doc(db, collectionName, docId));
            setData(contents.data());
        }
        getIndvContent();
    }, [])

    return (
        <>
            <Header />
            <main className="singleServiceContainer">
                <Cover title={data.Title}/>
                <SingleServiceContent data={data} collectionName={collectionName}/> 
                <ProcessSection/>
                <ServicesMini/>
                <FeedbackSection/>
                <ContactUsSection/>
                <Footer />
            </main>
        </>

    )
}

export default SingleService;