import {useEffect, useState} from "react";
import { validateForm } from "../../../../CommonComponents/FormValidators/validateForm";
import { getDocs,updateDoc,doc, collection} from "firebase/firestore";
import { db } from "../../../../ConfigurationSystem/firebase";

import SpinnerSvg from "../../../../ScreenLoader/screenLoader";
 

import "./systemStructureFormat.css";
const SystemStructureFormat=()=>{
    const [system,setSystem]=useState({});
    const [activeProjects, setActiveProjects]=useState(0);
    const [completedProjects,setCompletedProjects]=useState(0);
    const [activeCertificates, setActiveCertificates]=useState(0);
    const [completedCertificates,setCompletedCertificates]=useState(0);
    const [approvalRate,setApprovalRate]=useState(0);
    const [lastYearTurnOver,setLastYearTurnOver]=useState(0);
    const [loading,setLoading]=useState(true);

    const [labelOne,setLabelOne]=useState("");
    const [labelTwo,setLabelTwo]=useState("");
    const [labelThree,setLabelThree]=useState("");
    const [labelFour,setLabelFour]=useState("");

    const setContents=()=>{
        if(system[0]) setActiveProjects(system[0].activeProjects)
        if(system[0]) setCompletedProjects(system[0].completedProjects);
        if(system[0]) setActiveCertificates(system[0].activeCertificates);
        if(system[0]) setCompletedCertificates(system[0].completedCertificates);
        if(system[0]) setApprovalRate(system[0].approvalRate);
        if(system[0]) setLastYearTurnOver(system[0].lastYearTurnover);
    }
   
    const getContents=async ()=>{
        const collectionRef = collection(db, "System");
        const data = await getDocs(collectionRef);
        await setSystem(data.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
        await setLoading(false);
    }

    useEffect(()=>{
        setContents();
    },[system])
    useEffect(()=>{
        getContents();
    },[])
    
    
    const updateContents= async ()=>{
        const Doc = doc(db, "System", "LujrIPsyfAwbSxn3gFGZ");
        await updateDoc(Doc, { activeProjects: activeProjects, 
            completedProjects: completedProjects, activeCertificates: activeCertificates, 
            completedCertificates: completedCertificates, 
            approvalRate: approvalRate, lastYearTurnover:lastYearTurnOver });
            getContents();
    }

    if(loading) return <SpinnerSvg/>
    return(
        <form className="systemStructure-Form">
            <p> Total number of active projects : </p>
           <input type="number" value={activeProjects} onChange={(e)=>setActiveProjects(e.target.value)}/><br/>
           <h5 style={labelOne==="" ? {display:"none"} : {color:"red"}}> {labelOne} </h5>
            <p> Total number of completed projects : </p>
           <input type="number" value={completedProjects} onChange={(e)=>setCompletedProjects(e.target.value)}/><br/>


           <p> Total number of Certificates : </p>
           <input type="number" value={activeCertificates} onChange={(e)=>setActiveCertificates(e.target.value)}/><br/>
           <h5 style={labelOne==="" ? {display:"none"} : {color:"red"}}> {labelOne} </h5>
            <p> Total number of Certificates : </p>
           <input type="number" value={completedCertificates} onChange={(e)=>setCompletedCertificates(e.target.value)}/><br/>

           <h5 style={labelOne==="" ? {display:"none"} : {color:"red"}}> {labelTwo} </h5>
            <p> Total turnover : </p>
           <input type="number" value={lastYearTurnOver} onChange={(e)=>setLastYearTurnOver(e.target.value)}/><br/>
           <h5 style={labelOne==="" ? {display:"none"} : {color:"red"}}> {labelThree} </h5>
            <p> Total approval rate : </p>
           <input type="number" value={approvalRate} onChange={(e)=>setApprovalRate(e.target.value)}/><br/>
           <h5 style={labelOne==="" ? {display:"none"} : {color:"red"}}> {labelFour} </h5>
           <button className="btn btn-warning" onClick={(e)=>{
                setLoading(true);
                e.preventDefault();

                
                const resultOne=validateForm(0,activeProjects);
                const resultTwo=validateForm(0,completedProjects);
                const resultFive=validateForm(0,activeCertificates);
                const resultSix=validateForm(0,completedCertificates);
                const resultThree=validateForm(0,approvalRate);
                const resultFour=validateForm(0,lastYearTurnOver);
                
                if(!resultOne) setLabelOne("Active projects must contain a postive number");
                else setLabelOne("");
                
                if(!resultTwo) setLabelTwo("Completed projects must contain a positive number");
                else setLabelTwo("");

                if(!resultThree) setLabelThree("Turn over must be a postive number");
                else setLabelThree("");

                if(!resultFour) setLabelFour("Approval rate must be a postive number");
                else setLabelFour("");

                if(!resultFive) setLabelOne("Active certificates must contain a postive number");
                else setLabelOne("");
                
                if(!resultSix) setLabelTwo("Completed certificates must contain a positive number");
                else setLabelTwo("");

                if(resultOne && resultTwo && resultThree && resultFour && resultFive && resultSix){
                    updateContents();
                }

           }}> Set system </button> 
        </form>
    )
}
export default SystemStructureFormat;