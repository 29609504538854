

import { useLocation } from "react-router-dom";
import "./screenLoader.css";

const SpinnerImg = () => {
    const location = useLocation();
    return (
        <div className="loading-bg">
            <div className="loader-container">
                <div className="spinnerFlexBox">
                    <img
                        src="../../Images/Bikal Binod Adhikari Logo.png" // Update the path to the Bikal.png image
                        alt="Preloader"
                        width="200"
                        height="200"
                    />
                </div>
            </div>
        </div>
    );
};

export default SpinnerImg;
