import { Link , useLocation } from "react-router-dom";
import BrandTitle from "../../../../CommonComponents/Header/HeaderComponent/brandTitle";
import UserInformation from "../UserInformation/userInformation";
import DropDownMenu from "../DropDownMenu/dropDownMenu";
import { FiMenu } from "react-icons/fi";

import "./commonHeader.css";
import { useState } from "react";

const CommonHeader = () => {
    const [clicked,setClicked]=useState(false);

    const location=useLocation();

    return (
        <div className="headerFlex-dashboard primary-bg">
            <BrandTitle />
            <ul className="nav nav-bar menu-dashboard">
                <li className="nav-item">
                    <Link to="/dashboard/blogs"> {location.pathname==="/dashboard/blogs" ?<p className="activeNav"> Blogs </p> : <p> Blogs </p>} </Link>
                </li>
                <li className="nav-item">
                    <Link to="/dashboard/services"> {location.pathname==="/dashboard/services"?<p className="activeNav"> Services </p> : <p> Services </p>} </Link>

                </li>
                <li className="nav-item">
                    <Link to="/dashboard/projects"> {location.pathname==="/dashboard/projects"?<p className="activeNav"> Projects </p> : <p> Projects </p>} </Link>
                </li>
                <li className="nav-item">
                    <Link to="/dashboard/certificates"> {location.pathname==="/dashboard/certificates"?<p className="activeNav"> Certificates </p> : <p> Certificates </p>} </Link>
                </li>
                <li className="nav-item">
                    <Link to="/dashboard/system"> {location.pathname==="/dashboard/system"?<p className="activeNav"> System </p> : <p> System </p>} </Link>
                </li>
                <li className="nav-item">
                    <Link to="/dashboard/review"> {location.pathname==="/dashboard/review"?<p className="activeNav"> Review </p> : <p> Review </p>} </Link>
                </li>
                <li className="nav-item">
                    <Link to="/dashboard/products"> {location.pathname==="/dashboard/products"?<p className="activeNav"> Products </p> : <p> Products </p>} </Link>
                </li>
            </ul>
            <div className="iconContainer-dashboard">
                <FiMenu onClick={()=>setClicked(!clicked)}/>
            </div>
            <div className="userInfoContainer-dashboard">
                <UserInformation/>
            </div>
            <DropDownMenu clicked={clicked} location={location}/>
        </div>
    )
}
export default CommonHeader;