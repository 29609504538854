import "./addElementIcon.css";

import {AiFillPlusCircle} from "react-icons/ai";

const AddElementIcon = ({setImageChanged,setSelectedImageArray,setTotalImageInputs,setSelectedId,setSelectedImage,setSelectedTitle,setSelectedArticle,plusClicked,setPlusClicked,setLocation}) => {
    
    return (
        <div className="addComponentButton">
            
            <div className="currentMode">
                {(plusClicked)?<h5> Create mode </h5>:<h5> Update mode </h5>}
            </div>
            <AiFillPlusCircle label="Add" className="icon" onClick={()=>{
                setPlusClicked(true);
                setSelectedId(0);
                setSelectedImage(" ");
                setSelectedTitle(" ");
                setSelectedArticle(" ");
                setSelectedImageArray([]);
                setTotalImageInputs(1);
                setLocation(" ");
                setImageChanged(false);
            }}/>
        </div>
    )
}
export default AddElementIcon;