import { useContext,useEffect,useState } from "react";
import { getDocs , collection } from "firebase/firestore";
import { db } from "../ConfigurationSystem/firebase";

import Header from "../CommonComponents/Header/header";
import MainSlider from "../SliderComponents/MainSlider/mainSlider";
import PartnerSection from "./HomeComponents/PartnerSection/partnerSection";
import PointsFormat from "../CommonComponents/PointsFormat/pointsFormat";
import AdvantageSection from "./HomeComponents/AdvantageSection/advantageSection";
import GallerySection from "./HomeComponents/GallerySection/gallerySection";
import ProcessSection from "./HomeComponents/ProcessSection/processSection";
import FeedbackSection from "./HomeComponents/FeedbackSection/feedbackSection";
import ContactSection from "./HomeComponents/ContactSection/contactSection";
import Footer from "../CommonComponents/Footer/footer";
import SpinnerSvg from "../ScreenLoader/screenLoader";

import toggleContext from "../CommonComponents/Context/toggleContext";

const Home = () => { 
    useEffect(()=>{
        window.scroll({top:0 , behavior:"instant"});
    },[])
    
    const [windowWidth,setWindowWidth]=useState(window.innerWidth);
    const [data,setData]=useState([]);
    const [system,setSystem]=useState([]);
    const [currentContent, setCurrentContent] = useState([]);
    
    const [loading,setLoading]=useState(true);

    const blogCollectionRef = collection(db, "Services");
    const systemCollectionRef=collection(db, "System");


    const context=useContext(toggleContext);

    window.addEventListener("resize",()=>setWindowWidth(window.innerWidth));

    useEffect(() => {
        const getData = async () => {
            const data = await getDocs(blogCollectionRef);
            const systemContents=await getDocs(systemCollectionRef);
            await setData(data.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
            await setSystem(systemContents.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
            await console.log(system);
            await setCurrentContent({data: data.docs[0].data(), id:data.docs[0].id});
            setLoading(false);   
        }
        getData();
    }, [])


    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    useEffect(()=>{
        if(windowWidth>=991.98) context.setIsActive(false);
    },[windowWidth])

    if(loading) return <SpinnerSvg/>
    else {
        return (
            <>
                <Header/>
                <main>
                    {console.log(currentContent)}
                    <MainSlider data={data} currentContent={currentContent} setCurrentContent={setCurrentContent}/>
                    <PartnerSection />
                    <AdvantageSection system={system}/>
                    <GallerySection />
                    <ProcessSection />
                    <FeedbackSection />
                    <ContactSection />
                    
                    <Footer />
                </main>
            </>
        )
    }
}
export default Home;