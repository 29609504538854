import React from "react";
import "./skillsSection.css";

const SkillsSection = () => {
  return (
    <section className="client__area">
      <div className="container">
        <div className="row mb-60">
          <div className="col-xl-12">
            <div className="client__area-title">
            <span className="lineTitle">My Skills</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/swift-logo.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 sm-mb-30">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/node-js.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 lg-mb-30">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/laravel-php.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 sm-mb-30">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/vapor-swift.webp")}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 lg-mb-30">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/C-sharp.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 sm-mb-30">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/react-js.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 sm-mb-30">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/mongo-db.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/my-sql.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/figma.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/Jira.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/adobe-xd.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/php.png")}
                alt=""
              />
            </div>
          </div>
		  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/firebase.png")}
                alt=""
              />
            </div>
          </div>
		  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/linux.png")}
                alt=""
              />
            </div>
          </div>
		  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/magento.png")}
                alt=""
              />
            </div>
          </div>
		  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/wordpress.png")}
                alt=""
              />
            </div>
          </div>
		  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/kubernetes.png")}
                alt=""
              />
            </div>
          </div>
		  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/unity.png")}
                alt=""
              />
            </div>
          </div>
		  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/R-language.png")}
                alt=""
              />
            </div>
          </div>
		  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="client__area-item">
              <img
                src={require("../../../../Images/skills/ruby.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


export default SkillsSection;
