import React, { useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";

import "./map.css";


class ContactMap extends React.Component {
    constructor(props) {
      super(props);
      this.apiKey = "AIzaSyAcIeR-ji1_P9wQ2AkfwWGl9jyHooODJDA"; // Replace with your own API key
    }
  
    async componentDidMount() {
      const loader = new Loader({
        apiKey: this.apiKey,
        version: "weekly",
        libraries: ["places"],
      });
  
      try {
        const google = await loader.load();
        const map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: 27.6804683, lng: 85.3403923 },
          zoom: 15,
        });
  
        const marker = new google.maps.Marker({
          position: { lat: 27.6804683, lng: 85.3403923 },
          map: map,
        });
      } catch (error) {
        console.error("Error loading Google Maps API:", error);
      }
    }
  
    render() {
      return <div id="map" className="mapContainer-contacts" />;
    }
  }
  
export default ContactMap;
