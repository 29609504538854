import { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getDocs , collection } from "firebase/firestore";
import { db } from "../../../ConfigurationSystem/firebase";

import SpinnerSvg from "../../../ScreenLoader/screenLoader";


import "./gallerySection.css";

const GallerySection = () => {
    const [projects,setProjects]=useState([]);
    const [hoveredOne, setHoveredOne] = useState(false);
    const [hoveredTwo, setHoveredTwo] = useState(false);
    const [hoveredThree, setHoveredThree] = useState(false); 
    const [hoveredFour, setHoveredFour] = useState(false);
    const [loading,setLoading]=useState(true);
    
    const navigate=useNavigate();

    const projectCollectionRef=collection(db,"Projects");

    useEffect(()=>{
        const getData = async () => {
            const projectContents=await getDocs(projectCollectionRef);
            await setProjects(projectContents.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
            await setLoading(false);
        }
        getData();
    },[])  

    const stringCutter = (str) => {
        if (str) {
            const initial = str.split(" ");
            const mid = initial.slice(0, 3);
            const final = mid.join(" ");
            return final;
        }
    }
    
    if(loading) return <SpinnerSvg/>
    
    return (
        <div className="container-fluid"> 
            <div className="row galleryContainer">
                {projects.length > 0 && projects.slice(0,4).map((indx,i)=>(
                   <div style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/bikal-cv.appspot.com/o/ProjectsCovers%2F${indx.Image}?alt=media&token=f00024cf-8fcb-4c25-972d-d3202da4015e)`, backgroundSize: "contain" }} className="col-lg-3 col-md-3 col-12 galleryOne-about" onMouseOver={() => {
              
                    if(i===0) setHoveredOne(true);
                        else if(i===1) setHoveredTwo(true);
                        else if(i===2) setHoveredThree(true);
                        else if(i===3) setHoveredFour(true);
                    }}
                    onMouseLeave={() => {
                        if(i===0) setHoveredOne(false);
                        else if(i===1) setHoveredTwo(false);
                        else if(i===2) setHoveredThree(false);
                        else if(i===3) setHoveredFour(false);
                    }} onClick={()=>navigate(`/projects/${indx.id}`)}>
                    <div className={((hoveredOne && i===0) || (hoveredTwo && i===1) || (hoveredThree && i===2) || (hoveredFour && i===3) ) ? 'visible-gallery' : 'invisible-gallery'} style={{background:"rgba(0,31,63,.5)"}}>
                        <span className="lineTitle" data-aos="fade-down">Completed Projects</span>

                        <h4> {stringCutter(indx.Title)} </h4>
                        <p className="text-warning" style={{fontWeight:"bold"}}> Visit project </p>
                    </div>
                </div>
                ))}
            </div>
        </div>

    )
}
export default GallerySection;