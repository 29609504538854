import {useEffect, useState} from "react";

import { collection, getDocs } from "firebase/firestore";

import { db } from "../../../../ConfigurationSystem/firebase";

const LatestArticleSection = () => {
    const [data,setData]=useState([]);

    const blogCollectionRef = collection(db, "Blogs");

    useEffect(() => {
        const getData = async () => {
            const data = await getDocs(blogCollectionRef);
            setData(data.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
        }
        getData();
    }, [])

    return (
        <div className="gadget gadget--latest">
            <h3 className="gadget-title">Latest Articles</h3>
            <ul className="list">
                <picture>
                    <source
                        data-srcset="https://html.merku.love/axial/img/blog/17.webp"
                        srcset="https://html.merku.love/axial/img/blog/17.webp"
                        type="image/webp"
                    />
                    <img
                        className="lazy preview"
                        data-src="https://html.merku.love/axial/img/blog/17.webp"
                        src="https://html.merku.love/axial/img/blog/17.webp"
                        alt="preview"
                    />
                </picture>
                {data.slice(4,10).map(indx=>(
                    <li className="list-item">
                        <div style={{height:63,overflow:"hidden"}}>
                            <h5 className="title"> {indx.Title} </h5>
                        </div>
                        
                        <a className="link link-arrow" href={`blogs/${indx.id}`}>
                            Read now
                            <i className="icon-arrow_right icon"></i>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}
export default LatestArticleSection;