import { useState , useEffect } from "react";
import { Link } from "react-router-dom";
import { getDocs , collection } from "firebase/firestore";
import { db } from "../../../ConfigurationSystem/firebase";

import "./gallerySection.css";
import SpinnerSvg from "../../../ScreenLoader/screenLoader";

const GallerySection = () => {

    const [projects,setProjects]=useState([]);
    const [lightPurplishClassOne,setLightPurplishClassOne]=useState("unLightPurplishFade");
    const [lightPurplishClassTwo,setLightPurplishClassTwo]=useState("unLightPurplishFade");
    const [lightPurplishClassThree,setLightPurplishClassThree]=useState("unLightPurplishFade");
    const [lightPurplishClassFour,setLightPurplishClassFour]=useState("unLightPurplishFade");
    const [lightPurplishClassFive,setLightPurplishClassFive]=useState("unLightPurplishFade");
    const [lightPurplishClassSix,setLightPurplishClassSix]=useState("unLightPurplishFade");
    
    const [loading,setLoading]=useState(true);
    const projectCollectionRef=collection(db,"Projects");

    useEffect(()=>{
        const getData = async () => {
            const projectContents=await getDocs(projectCollectionRef);
            await setProjects(projectContents.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
            await setLoading(false);
        }
        getData();
    },[])  

    if(loading) return <SpinnerSvg/>
    return (
        <section className="gallery section">
            <div className="container">
                <div className="gallery_header section_header">
                    <span className="lineTitle"> Magic I've Done </span>
                    <h2 className="title">
                        My
                        <span className="ternary"> Gallery</span>
                    </h2>
                </div>
                <div className="row galleryPart"> 
                    {projects.length > 0 && projects.slice(0,6).map((project,i) => (
                        
                        <div className="col-lg-4 col-md-4 col-12">
                            <div data-aos="fade-up" style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/bikal-cv.appspot.com/o/ProjectsCovers%2F${project.Image}?alt=media&token=f00024cf-8fcb-4c25-972d-d3202da4015e)` }} className="clickableImage">
                                <div className={i===0?lightPurplishClassOne:i===1?lightPurplishClassTwo:i===2?lightPurplishClassThree:i===3?lightPurplishClassFour:i===4?lightPurplishClassFive:lightPurplishClassSix} 
                                
                                onMouseEnter={() => i===0?setLightPurplishClassOne("lightPurplishFade"):i===1?setLightPurplishClassTwo("lightPurplishFade"):i===2?setLightPurplishClassThree("lightPurplishFade"):i===3?setLightPurplishClassFour("lightPurplishFade"):i===4?setLightPurplishClassFive("lightPurplishFade"):setLightPurplishClassSix("lightPurplishFade")} 
                                
                                onMouseLeave={() => i===0?setLightPurplishClassOne("unLightPurplishFade"):i===1?setLightPurplishClassTwo("unLightPurplishFade"):i===2?setLightPurplishClassThree("unLightPurplishFade"):i===3?setLightPurplishClassFour("unLightPurplishFade"):i===4?setLightPurplishClassFive("unLightPurplishFade"):setLightPurplishClassSix("unLightPurplishFade")}>

                                    <h3> {`Project ${i+1}`} </h3>
                                    <Link to={`/projects/${project.id}`}><button className="btn btn-outline-warning clickButton"> Visit </button></Link>
                                </div>
                            </div>
                        </div>
                    ))}

                    
                </div>
            </div>
        </section>

    )
}
export default GallerySection;