import Header from "../CommonComponents/Header/header";
import Footer from "../CommonComponents/Footer/footer";
import Cover from "../CommonComponents/Cover/cover";
import FormatDisplayer from "./ProductsContents/formatDisplayer";
import SpinnerSvg from "../ScreenLoader/screenLoader";

import { useEffect, useState } from "react";
import { getDoc , doc} from "firebase/firestore";
import { db } from "../ConfigurationSystem/firebase";
import { useParams } from "react-router-dom";



import "./products.css";


const Product=()=>{
    const docId=useParams().id;
    
    const [loading,setLoading]=useState(true);
    const [product,setProduct]=useState([]);
    useEffect(()=>{
        const dataFetchers=async ()=>{
            const content=await getDoc(doc(db, "Products", docId));
            await setProduct(content.data());
            await setLoading(false);
        }
        dataFetchers();
    },[]) 


    if(loading) return <SpinnerSvg/>
    return (
        <>
            <Header />
            <main>
                <Cover title={product.Title}/>
                <FormatDisplayer product={product} docId={docId}/>
                <Footer />
            </main>

        </>
    )
}
export default Product;