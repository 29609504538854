import React from "react";
import {FiMap} from "react-icons/fi";
import {FaPhoneVolume} from "react-icons/fa";
import "./footer.css";

const Footer = () => {
  return (
   
    <footer id="dk-footer" className="dk-footer primary-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-4">
            <div className="dk-footer-box-info">
              <a href="/" className="footer-logo">
              <img
               src={require("../../../Images/Bikal Binod Adhikari Logo.png")}
               style={{ width: "150px", height: "120px", marginLeft: 10, marginRight: 10 }}
               alt="Bikal  Binod Adhikari"
              />
              </a>
              <p className="footer-info-text">
                Get your's projects completed in time
              </p>
              <div className="footer-social-link">
                <h3>Follow Me</h3>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/bikalbinodadhikari/">
                      <i className="icon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/bikalbinodadhikari/?hl=en">
                      <i className="icon-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://np.linkedin.com/in/bikal-binod-adhikari">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-8">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="contact-us">
                  <div className="contact-icon">
                    <a href="https://www.google.com/maps/search/?api=1&query=27.738135,85.333133"><FiMap className="icon"/></a>
                  </div>
                  <div className="contact-info">
                    <h3>Mahadevsthan Marga</h3>
                    <p>Koteshwor</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="contact-us contact-us-last">
                  <div className="contact-icon">
                  <a href="tel:+977-9809107997"><FaPhoneVolume className="icon"/></a>
                  </div>
                  <div className="contact-info">
                    <h3>9809107997</h3>
                    <p>Call Me</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="footer-widget footer-left-widget">
                  <div className="section-heading">
                    <h3>Useful Links</h3>
                    <span className="animate-border border-black"></span>
                  </div>
                  <ul>
                    <li>
                      <a href="/about">About Me</a>
                    </li>
                    <li>
                      <a href="/services">Services</a>
                    </li>
                    <li>
                      <a href="/projects">Projects</a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a href="/contacts">Contact Me</a>
                    </li>
                    <li>
                      <a href="/blogs">Blog</a>
                    </li>
                    <li>
                      <a href="/faq">Faqs</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="footer-widget">
                  <div className="section-heading">
                    <h3>Currently Working </h3>
                    <span className="animate-border border-black"></span>
                    <div className="poweredByContainer container">
                      <div className="poweredByContent">
                        <a href="https://digitalguidenp.com" target="_blank" rel="noopener noreferrer">
                          <img
                            src={require("../../../Images/Digital Guide Nepal.png")}
                            style={{ width: 200, marginLeft:-60}}
                            alt="Current Company"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-12" style={{textAlign:"center"}}>
              <span>Bikal Binod Adhikari © All Rights Reserved</span>
            </div>
            
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
