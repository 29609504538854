import { useContext } from "react";

import toggleContext from "../../../../CommonComponents/Context/toggleContext";
import "./userInformation.css";

const UserInformation=()=>{
    const context=useContext(toggleContext);
    
    return(
        <div className="displayFlex" style={{alignItems:"center"}}>
            <button className="btn btn-warning signOutButton" onClick={()=>context.setSignOut(true)}> Sign&nbsp;out </button>
        </div>
    )
}
export default UserInformation;