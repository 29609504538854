import { useEffect } from "react";

import Header from "../CommonComponents/Header/header";
import Footer from "../CommonComponents/Footer/footer";
import Cover from "../CommonComponents/Cover/cover";
import BlogContainer from "./BlogComponents/BlogContainer/blogContainer";


const Blogs = () => {
    useEffect(()=>{
        window.scroll({top:0 , behavior:"instant"});
    },[])
    return (
        <>
            <Header />
            <main>
                <Cover title={"Blogs"} />
                <BlogContainer/>
                <Footer />

            </main>
        </>
    )
}
export default Blogs;