import "./timeline.css";

const TimeLine = () => {
    return (
        <div className="container circleContainer">
            <div className="row timeLineContainer">
                <div className="line bg-warning" />
                <div className="col-lg-3 col-md-3 col-12">
                    <div className="circle">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                    <div className="circle circleOne">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                    <div className="circle circleTwo">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                    <div className="circle circleThree">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TimeLine;