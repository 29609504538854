import MapSection from "../MapSection/mapSection";

import "./contactSection.css";

const ContactSection = () => {
    return ( 
        <section className="contact section">
            <div className="container">
                <div className="contact-wrapper col-lg-6 col-xxl-auto">
                    <div className="contact_header section_header">
                        <span className="lineTitle">Reach Me</span>
                        <h2 className="title">
                            <span className="ternary">My Details </span>
                            
                        </h2>
                        <p className="text">
                            My contact information are given below. Feel free to use any handles provided.
                        </p>
                    </div>
                    <ul className="contact-info">
                        <li className="contact-info_group">
                            <span className="name">Address</span>
                            <span className="content">Mahadevsthan Marga, Koteshwor</span>
                        </li>
                        <li className="contact-info_group">
                            <span className="name">Email</span>
                            <span className="content d-inline-flex flex-column">
                                <a className="link" href="mailto:mebikalbinodadhikari@gmail.com">mebikalbinodadhikari@gmail.com</a>
                            </span>
                        </li>
                        <li className="contact-info_group">
                            <span className="name">Phone</span>
                            <span className="content d-inline-flex flex-column">
                                <a className="link" href="tel:9809107997">9809107997</a>
                            </span>
                        </li>
                    </ul>
                    
                    <div className="footer-social-link">
                <h3>Follow Me</h3>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/bikalbinodadhikari/">
                      <i className="icon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/bikalbinodadhikari/?hl=en">
                      <i className="icon-instagram"></i>
                    </a>
                  </li>
                  <li>
                  <a href="https://np.linkedin.com/in/bikal-binod-adhikari">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
                </div>
                
                <div className="contact_map">
                    <MapSection/>
                </div>
            </div>
        </section>
    )
}
export default ContactSection;