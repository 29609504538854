import {initializeApp} from "firebase/app";
import {getFirestore} from "@firebase/firestore";
import {getAuth} from "firebase/auth";

import { getStorage } from "firebase/storage";


//Dotenv not working at the moment, to test need to enter the keys manually
const firebaseConfig={
  
  apiKey: "AIzaSyDcU9yz8NB2A3Iq6efp7_jQhziMMbBmGiw",
  authDomain: "bikal-cv.firebaseapp.com",
  databaseURL: "https://bikal-cv-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "bikal-cv",
  storageBucket: "bikal-cv.appspot.com",
  messagingSenderId: "1088508527195",
  appId: "1:1088508527195:web:2d3abee0d4be21bb4ef57e",
  measurementId: "G-EWKFEY1T3F"

}

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);

export const db = getFirestore(app);

export const auth=getAuth(app);