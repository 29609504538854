import { useEffect } from "react";

import Header from "../CommonComponents/Header/header";
import Footer from "../CommonComponents/Footer/footer";
import Cover from "../CommonComponents/Cover/cover";
import FAQSection from "./FAQComponents/faqSection";
import GallerySection from "../About/AboutComponents/GallerySection/gallerySection";
import ContactUsSection from "./FAQComponents/ContactUsSection/contactUsSection";


const FAQ=()=>{
    useEffect(()=>{
        window.scroll({top:0 , behavior:"instant"});
    },[])
    return(
        <>
            <Header />
            <main>
                <Cover title={"FAQ"} />
                <FAQSection />
                <GallerySection/>
                <ContactUsSection/>
                <Footer />
            </main>
        </>
    )
}
export default FAQ;