import { Link } from "react-router-dom"

import "./dropDownMenu.css";
const DropDownMenu = ({clicked,location}) => {
    return (
        <div className={clicked?'dropDownMenu-dashboard primary-bg active':'dropDownMenu-dashboard primary-bg inactive'}>
            <Link to="/dashboard/blogs"> {location.pathname==="/dashboard/blogs" ?<p className="activeNavSml"> Blogs </p> : <p> Blogs </p>} </Link>
            <Link to="/dashboard/services"> {location.pathname==="/dashboard/services"?<p className="activeNavSml"> Services </p> : <p> Services </p>} </Link>
            <Link to="/dashboard/projects"> {location.pathname==="/dashboard/projects"?<p className="activeNavSml"> Projects </p> : <p> Projects </p>} </Link>
            <Link to="/dashboard/certificates"> {location.pathname==="/dashboard/certificates"?<p className="activeNavSml"> Certificates </p> : <p> Certificates </p>} </Link>
            <Link to="/dashboard/system"> {location.pathname==="/dashboard/system"?<p className="activeNavSml"> System </p> : <p> System </p>} </Link>
            <Link to="/dashboard/review"> {location.pathname==="/dashboard/review"?<p className="activeNavSml"> Review </p> : <p> Review </p>} </Link>
            <Link to="/dashboard/products"> {location.pathname==="/dashboard/products"?<p className="activeNavSml"> Products </p> : <p> Products </p>} </Link>
        </div>
    )
}

export default DropDownMenu;