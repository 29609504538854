import { useState } from "react";
import ToggleContext from "./toggleContext";

const ToggleState=(props)=>{
    const [isActive, setIsActive] = useState(false);
    const [signOut,setSignOut]=useState(false);
    const [currentUser,setCurrentUser] = useState("");

    const collection={
        isActive:isActive,
        setIsActive:setIsActive,
        currentUser:currentUser,
        setCurrentUser:setCurrentUser,
        signOut:signOut,
        setSignOut:setSignOut
    }

    return(
        <ToggleContext.Provider value={collection}>
            {props.children}
        </ToggleContext.Provider>
    )
}
export default ToggleState;