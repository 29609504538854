import "./aboutMini.css";

const AboutMini=()=>{

    return(
        <section className="comn aboutMini section">
                <div className="container displayFlex flexXlNoWrap alignItemsXlCenter justifyContentBetween">
                    <div className="comn_header section_header col-lg-6 col-xl-auto">
                        <span className="lineTitle" data-aos="fade-down">Hi!</span>
                        <h2 className="title" data-aos="fade-right">
                            Myself
                            <span className="ternary"> Bikal Binod Adhikari</span>
                           
                        </h2>
                        <p className="text" data-aos="fade-up" data-aos-delay="20">
                        A self-motivated undergraduate student proficient in iOS Application Development and Web/Mobile Development.<br/>
                        Quick to learn and adapt to new technologies.<br/><br/>
                        Passionate about traveling and exploring the latest advancements in the field. Additionally, knowledgeable in DevOps methodologies.<br/><br/>
                        I have successfully deployed applications across various countries.
                        </p>
                        <ul className="comn_header-list">
                            <li className="comn_header-list_item displayFlex " data-aos="fade-up" data-aos-delay="30">
                                <i className="icon-arrow_right icon"></i>
                                Self Motivated
                            </li>
                            <li className="comn_header-list_item displayFlex " data-aos="fade-up" data-aos-delay="30">
                                <i className="icon-arrow_right icon"></i>
                                Quick Learner
                            </li>
                            <li className="comn_header-list_item displayFlex " data-aos="fade-up" data-aos-delay="40">
                                <i className="icon-arrow_right icon"></i>
                                Able to learn from Mistakes
                            </li>
                            <li className="comn_header-list_item displayFlex " data-aos="fade-up" data-aos-delay="40">
                                <i className="icon-arrow_right icon"></i>
                                Business Mindset
                            </li>
                        </ul>
                        <a className="btn" href="/faq" data-aos="fade-up" style={{transition:".25s ease-in-out"}}>Call Me</a>
                    </div>
                    <div className="comn_img" data-aos="zoom-in" data-aos-duration="700">
                        <img src={require("../../../../Images/Adhikari Bikal Binod.JPG")} className="img-fluid"/>
                    </div>
                </div>
            </section>
    )
}
export default AboutMini;