import LatestArticleSection from "./OptionSectionComponents/latestArticleSection";
import SocialAndSubs from "./OptionSectionComponents/socialAndSubs";

import "./optionSection.css";

const OptionSection = () => { 
    return (
        <aside className="gizmo gizmo--blog">
            <LatestArticleSection/>
        </aside>
    )
}

export default OptionSection;