import "./skillsSection.css";

import ProgressBarCollection from "./SkillsSectionComponents/progressBarCollection";

const SkillsSection = () => {
    return (
       
        <div className="container skillsContainer" style={{ marginTop: '40px' }}>
            <div className="row">
                <div className="col-lg-6 col-12 imgContainer-skills">
                    <div className="skills_img" data-aos="zoom-in" data-aos-duration="600">
                        <picture>
                            

                        <img
                            src={require("../../../../Images/Bikal-Binod-Adhikari.png")}
                            alt=""
                        />
                       
                        </picture>
                    </div>
                </div>
                <div className="col-lg-6 col-12 wrapper wrapper--skills">
                    <div className="skills_header section_header">
                        <span className="lineTitle" data-aos="fade-down">I build only quality things</span>
                        <h2 className="title" data-aos="fade-right">
                            Trusted Quality for Over
                            <span className="ternary"> 5 Years</span>
                        </h2>
                        <p className="text" data-aos="fade-up" data-aos-delay="50">
                        When it comes to cost effetiveness, quality and productive projects. People looks for the best.
                        </p>
                        <ProgressBarCollection/>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default SkillsSection;