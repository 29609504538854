import { useEffect } from "react";

import Header from "../CommonComponents/Header/header";
import AboutMini from "./AboutComponents/CoverSection/aboutMini";
import SkillsSection from "./AboutComponents/SkillsSection/skillsSection";
import Footer from "../CommonComponents/Footer/footer";
import Cover from "../CommonComponents/Cover/cover";
import GallerySection from "./AboutComponents/GallerySection/gallerySection";
import ServicesMini from "../Services/ServicesComponents/ServicesMini/servicesMini";


const About=()=>{
    useEffect(()=>{
        window.scroll({top:0 , behavior:"instant"});
    },[])
    return(
        <> 
            <Header/>
            <main>   
                <Cover title={"About"}/>
                <AboutMini/>
                <SkillsSection/> 
                <ServicesMini/>
                <GallerySection/>
             
                <div style={{marginTop:150}}/>
                <Footer/> 
            </main>
        </>
    )
}
export default About;