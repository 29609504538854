import { useEffect } from "react";
import Header from "../CommonComponents/Header/header";
import ContactInformation from "./ContactsComponents/ContactInformation/contactInformation";
import Cover from "../CommonComponents/Cover/cover";
import Footer from "../CommonComponents/Footer/footer";
import ContactMap from "./ContactsComponents/Map/map";
import PartnerSection from "../Home/HomeComponents/PartnerSection/partnerSection";

import "./contacts.css";
const Contacts = () => {
    useEffect(()=>{
        window.scroll({top:0 , behavior:"instant"});
    },[])
    return (
        <>
            <Header />
            <main>
                <Cover title={"Contacts"}/>
                <ContactInformation />
                <ContactMap/>

                <div style={{marginTop:-250}}/>
                
                <Footer/>
            </main>
        </>
    )
}
export default Contacts;