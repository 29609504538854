import SpinnerSvg from '../../ScreenLoader/screenLoader';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../ConfigurationSystem/firebase';
import { useEffect ,useState} from 'react';

import 'swiper/css';
import "./reviewSlider.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import parse from "html-react-parser";

const ReviewSlider = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const collectionRef = collection(db, "Review");

    const getData = async () => {
        const data = await getDocs(collectionRef);
        await setData(data.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
        setLoading(false);
    }


    useEffect(() => {
        getData();
    }, [])

    if (loading) return <SpinnerSvg />
    else {
        return (
            <Swiper modules={[Navigation, Pagination, Scrollbar, A11y]} pagination={{ clickable: true }} spaceBetween={0} slidesPerView={1} className="swiper">
                {data.map(indx=>(
                <SwiperSlide>
                    <li className="feedback_slider-slide">
                        <div className="feedback_slider-slide_wrapper displayFlex flex-column justifyContentBetween">
                            <p className="feedback_slider-slide_text">
                            {(indx.Article) && parse(indx.Article)}
                            </p>
                            <div className="feedback_slider-slide_author displayFlex ">
                               
                                <div className="wrapper">
                                    <span className="name">{indx.Title}</span>
                                    <span className="name">
                                        <img src={`https://firebasestorage.googleapis.com/v0/b/bikal-cv.appspot.com/o/ReviewCovers%2F${indx.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23}`} />
                                    </span>
                                    <span className="company">{indx.MiniStart}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </SwiperSlide>
                ))}

            </Swiper>

        );
    }
};

export default ReviewSlider;