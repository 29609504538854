import FourStats from "../../../CommonComponents/FourStats/fourStats";
import "./advantageSection.css";

const AdvantageSection = ({system}) => {
    return ( 
        <section className="advantages section-nopb">
            <div
                className="container advantages_container">
                <div className="advantages_header section_header col-xl-auto">
                    <span className="lineTitle" data-aos="fade-down">Your Dream Projects</span>
                    <h2 className="title" data-aos="fade-right">
                        Why Choose
                        <span className="ternary"> Me</span>
                    </h2> 
                    <FourStats fourStatsClass={"advantages_header-list"} system={system}/>
                </div>
                <div className="advantages_list">
                    <p className="advantages_list-text" data-aos="fade-left">
                        Here are some the reasons why you should choose me for your next projects : 
                    </p>
                    <ul className="advantages_list-list">
                        <li className="advantages_list-list_item displayFlex">
                            <span className="number" data-aos="zoom-in">01</span>
                            <div className="wrapper">
                                <h4 className="title" data-aos="fade-up" data-aos-delay="50">Expertise</h4>
                                <p className="text" data-aos="fade-up" data-aos-delay="100">
                                    From small to large enterprise systems that needs a better planning.
                                </p>
                            </div>
                        </li>
                        <li className="advantages_list-list_item displayFlex">
                            <span className="number" data-aos="zoom-in" data-aos-delay="100">02</span>
                            <div className="wrapper">
                                <h4 className="title" data-aos="fade-up" data-aos-delay="150">Project Experience</h4>
                                <p className="text" data-aos="fade-up" data-aos-delay="200">
                                    I've completed more than 15 projects that my clients have admired.
                                </p>
                            </div>
                        </li>
                        <li className="advantages_list-list_item displayFlex">
                            <span className="number" data-aos="zoom-in" data-aos-delay="200">03</span>
                            <div className="wrapper">
                                <h4 className="title" data-aos="fade-up" data-aos-delay="250">Implement New Ideas</h4>
                                <p className="text" data-aos="fade-up" data-aos-delay="300">
                                    I've always tried best alternatives to achieve the dream projects of my clients. Implemented new ideas in different ways.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}
export default AdvantageSection