import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';

import { BsArrowLeftCircle } from 'react-icons/bs';
import { BsArrowRightCircle } from 'react-icons/bs';

import BlogsList from './blogsList';

const PaginatedItems = ({ data, itemsPerPage , isBlogs}) => {
    
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = data.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(data.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            { isBlogs && <BlogsList data={currentItems}/>}
            <div className="col-lg-6 col-12 paginationContainer-blogs">
                <ReactPaginate className='pageContainer'
                    breakLabel="..."
                    nextLabel={<BsArrowRightCircle className="iconArrow-blogs" />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={<BsArrowLeftCircle className="iconArrow-blogs" />}
                    renderOnZeroPageCount={null}
                    activeClassName="activeCls"
                />
            </div>
        </>
    );
}
export default PaginatedItems;