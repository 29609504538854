import CommonHeader from "./DashboardComponents/CommonHeader/commonHeader";
import StructureFormat from "./DashboardComponents/StructureFormat/structureFormat";
import SystemStructureFormat from "./DashboardComponents/StructureFormat/systemStructureFormat";
import { useContext } from "react";
import toggleContext from "../../CommonComponents/Context/toggleContext";

import { Navigate } from "react-router-dom";

const Dashboard=({loggedIn,title,collectionName})=>{
    const context=useContext(toggleContext);
    if(loggedIn && !context.signOut){
        return(
            <>
                <CommonHeader/>
                {collectionName === "System" ? <SystemStructureFormat title={title}/> : <StructureFormat title={title} collectionName={collectionName}/>}
            </>
        )
    }
    else return <Navigate to="/admin/login"/>
}
export default Dashboard;