import TimeLine from "./ProcessComponents/timeline";

import "./processSection.css";

const ProcessSection = () => {
    return (
        <section className="process section primary-bg">
            <div className="container displayFlex flex-wrap justifyContentBetween align-items-end">
                <div className="process_header section_header">
                    <span className="lineTitle">My Process</span>
                    <h2 className="title">
                      
                        <span className="ternary"> of Working </span>
                    </h2> 
                </div>
                <p className="process_text">
                    This is the step-wise process of how I will start your work. From your requirement process analysis to executing to implementing them in real life. 
                </p>
            </div>
            <div className="container-fluid process_fluid p-0">
                <div className="container">
                    <ul className="process_steps progress-tracker progress-tracker--vertical">
                        <li className="process_steps-step progress-step">
                            <div className="progress-marker">
                                <span className="progress-marker_spot"></span>
                                <span className="progress-marker_spot--underlay"></span>
                            </div>
                            <div className="process_steps-step_wrapper">
                                <h4 className="title">Leave a request on the website</h4>
                                <p className="description">
                                    Here we analyze your request or a prototype of your design and plan further to implement
                                </p>
                            </div>
                        </li>
                        <li className="process_steps-step progress-step">
                            <div className="progress-marker">
                                <span className="progress-marker_spot"></span>
                                <span className="progress-marker_spot--underlay"></span>
                            </div>
                            <div className="process_steps-step_wrapper">
                                <h4 className="title">Сalculation of the cost of the service</h4>
                                <p className="description">
                                    Here we estimate the total cost for the project
                                </p>
                            </div>
                        </li>
                        <li className="process_steps-step progress-step">
                            <div className="progress-marker">
                                <span className="progress-marker_spot"></span>
                                <span className="progress-marker_spot--underlay"></span>
                            </div>
                            <div className="process_steps-step_wrapper">
                                <h4 className="title">Signing of a contract</h4>
                                <p className="description">We form a contract with the details and the cost of the project. Both the parties sign them.</p>
                            </div>
                        </li>
                        <li className="process_steps-step progress-step">
                            <div className="progress-marker">
                                <span className="progress-marker_spot"></span>
                                <span className="progress-marker_spot--underlay"></span>
                            </div>
                            <div className="process_steps-step_wrapper">
                                <h4 className="title">Execution of works</h4>
                                <p className="description">
                                    I will finally put your work in action considering the previously discussed requirements.
                                </p>
                            </div>
                        </li>
                    </ul>

                    <div className="row timeStamp">
                        <TimeLine/>
                        <div className="col-lg-3 col-md-3 col-12">

                            <h3> Leave a request </h3>

                            <p> Here we analyze your request or a prototype of your design and plan further to implement </p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12">
                            <h3>Cost calculation </h3>

                            <p>     Here we estimate the total cost for the project
                                </p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12">
                            <h3> Signing of a contract </h3>

                            <p> We form a contract with the details and the cost of the project. Both the parties sign them. </p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 lastStamp">
                            <h3> Execution of Works </h3>

                            <p> I finally put your work in action considering the previously discussed requirements.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ProcessSection;