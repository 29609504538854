import {useState,useEffect} from "react";
import { collection, getDocs } from "firebase/firestore";

import {db} from "../../../ConfigurationSystem/firebase";
import SpinnerSvg from "../../../ScreenLoader/screenLoader";

import FourStats from "../../../CommonComponents/FourStats/fourStats";
import "./numbersContainer.css";

const NumbersContainer = () => {
    const [system,setSystem]=useState([]);
    const [loading,setLoading]=useState(true);

    const systemCollectionRef=collection(db,"System");
    
    useEffect(() => {
        const getData = async () => {
            const data = await getDocs(systemCollectionRef);
            await setSystem(data.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
            await setLoading(false);
        }
        getData();
    }, [])

    if(loading) return <SpinnerSvg/>
    
    return (
        <section className="numbers primary-bg section">
            <div className="container">
                <div className="row g-3 g-lg-4 justifyContentBetween align-items-end">
                    <div className="numbers_header section_header col-lg-6 col-xl-5">
                        <span className="lineTitle">Traveling is fun!</span>
                        <h2 className="title">
                            
                            <span className="ternary">Nature is Love</span>
                        </h2>
                        <p className="text">
                        Of course, traveling also comes with challenges and uncertainties, but the experiences and growth it offers make it truly worthwhile. So, whether it's a short weekend getaway or an extended trip to faraway lands, traveling always promises new adventures and unforgettable moments.
                        </p>
                    </div>
                   
                    
                    <img src={require("../../../../Images/Bikal Binod Adhikari  enjoying in bridge.JPG")} className="img-fluid numbersContainerImage" data-aos="zoom-in" data-aos-duration="600" data-aos-once="true"/>
                </div>
            </div>
        </section>
    )
}
export default NumbersContainer;