import { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";

const FourStats = ({fourStatsClass,system}) => {
    const [counterOn,setCounterOn]=useState(false);

    return (
        <ul className={`${fourStatsClass} d-sm-flex flex-wrap justify-content-around `}>
            <li className="advantages_header-list_item">
                <ScrollTrigger onEnter={() => setCounterOn(true)}>
                    <h2 className="number countNum" data-value="5" data-suffix="+"> {counterOn && <CountUp start={0} end={system[0] ? system[0].completedProjects : 0} duration={2} delay={0} />}+</h2>
                </ScrollTrigger>
                <span className="label">Projects Every Year</span>
            </li>
            <li className="advantages_header-list_item">
                <ScrollTrigger>
                    <h2 className="number countNum" data-value="10" data-separator="."> {counterOn && <CountUp start={0} end={system[0] ? system[0].lastYearTurnover:0} duration={4} delay={0} />} </h2>
                </ScrollTrigger>
                <span className="label">Satisfied Clients</span>
            </li>
            <li className="advantages_header-list_item">
                <ScrollTrigger>
                    <h2 className="number countNum" data-value="90" data-suffix="%"> {counterOn && <CountUp start={0} end={system[0] ? system[0].approvalRate : 0} duration={2} delay={0} />} %</h2>
                </ScrollTrigger>
                <span className="label">Satisfaction Rate</span>
            </li>
            <li className="advantages_header-list_item">
                <ScrollTrigger>
                    <h2 className="number countNum" data-value="3" data-suffix="+"> {counterOn && <CountUp start={0} end={system[0] ? system[0].activeProjects : 0} duration={2} delay={0} />} + </h2>
                </ScrollTrigger>
                <span className="label">Active Projects</span>
            </li>
        </ul>
    )
}
export default FourStats;