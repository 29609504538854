import {useEffect,useContext,useState} from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "../../dashboard";


import SpinnerSvg from "../../../../ScreenLoader/screenLoader";
import toggleContext from "../../../../CommonComponents/Context/toggleContext";
import { onAuthStateChanged , getAuth } from "firebase/auth";


const ReviewContents=({loggedIn,setLoggedIn})=>{
    const [loading,setLoading]=useState(true);
    const context=useContext(toggleContext);
    const navigate=useNavigate();

    const auth=getAuth();

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) { 
                await context.setCurrentUser(user.email);
                if (!context.signOut) {
                    setLoggedIn(true);
                    setLoading(false);
                }
            }
            else {
                navigate("/dashboard/blogs");
                setLoggedIn(false);
            }

        })
    }, [navigate])

    if(loading) return <SpinnerSvg />
    return <Dashboard loggedIn={loggedIn} title={"Review"} collectionName={"Review"}/>
}

export default ReviewContents;