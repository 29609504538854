import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home/home";
import About from "./Components/About/about";
import Demo from "./Components/Demo/demo";
import Blogs from "./Components/Blogs/blogs";
import Services from "./Components/Services/services";
import Login from "./Components/Login/login";
import ReviewContents from "./Components/AdminComponents/Dashboard/DashboardComponents/ReviewContents/reviewContents";
import ServiceContents from "./Components/AdminComponents/Dashboard/DashboardComponents/ServiceContents/serviceContents";
import ProjectContents from "./Components/AdminComponents/Dashboard/DashboardComponents/ProjectContents/projectContents";
import Projects from "./Components/Projects/projects";
import Certificates from "./Components/Certificates/certificates";
import CertificatesContents from "./Components/AdminComponents/Dashboard/DashboardComponents/CertificatesContents/certificatesContents";
import Contacts from "./Components/Contacts/contacts";
import FAQ from "./Components/FAQ/faq";
import ErrorPage from "./Components/ErrorPage/404page";
import SingleService from "./Components/SingleService/singleService";
import SystemContents from "./Components/AdminComponents/Dashboard/DashboardComponents/SystemContents/systemContents";
import BlogContents from "./Components/AdminComponents/Dashboard/DashboardComponents/BlogContents/blogContents";
import Product from "./Components/Products/products";

import { useState } from "react";
import SingleBlog from "./Components/SingleBlog/singleBlog";
import ProductContents from "./Components/AdminComponents/Dashboard/DashboardComponents/ProductContents/productContents";

const App = () => {

  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/about" element={<About />} />
      <Route path="/demo" element={<Demo />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blogs/:id" element={<SingleBlog/>}/>
      <Route path="/services" element={<Services />} />
      <Route path="/services/:id" element={<SingleService collectionName={"Services"}/>}/>
      <Route path="/projects" element={<Projects/>}/>
      <Route path="/projects/:id" element={<SingleService collectionName={"Projects"}/>}/>
      <Route path="/certificates" element={<Certificates/>}/>
      <Route path="/certificates/:id" element={<SingleService collectionName={"Certificates"}/>}/>
      <Route path="/product/:id" element={<Product component={(props) => (
        <Product
          {...props} 
          key={props.match.params.id}
        />
      )} />} />

      <Route path="/contacts" element={<Contacts/>}/>
      <Route path="/faq" element={<FAQ/>}/>
      <Route path="/error" element={<ErrorPage/>}/>
  

      <Route path="/dashboard/blogs" element={<BlogContents loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
      <Route path="/dashboard/services" element={<ServiceContents loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
      <Route path="/dashboard/projects" element={<ProjectContents loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>}/>
      <Route path="/dashboard/certificates" element={<CertificatesContents loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>}/>
      <Route path="/dashboard/system" element={<SystemContents loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>}/>
      <Route path="/dashboard/review" element={<ReviewContents loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>}/>
      <Route path="/dashboard/products" element={<ProductContents loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>}/>
      <Route path="/admin/login" element={<Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} />} />
      <Route path="*" element={<ErrorPage/>}/>
    </Routes>
  )
}
export default App;