import ProgressBar from "@ramonak/react-progress-bar";
import "./progressBarCollection.css";

const ProgressBarCollection=()=>{
    return (
        <>
            <div className="progressBarContainer">
                <h5> Swift </h5> 
                <ProgressBar completed={90} bgColor="#fd6631"/>
            </div>
            <div className="progressBarContainer">
                <h5> DevOps </h5> 
                <ProgressBar completed={88} bgColor="#326ce5"/>
            </div>
            <div className="progressBarContainer">
                <h5> Networking </h5> 
                <ProgressBar completed={89} bgColor="#326ce5"/>
            </div>
            <div className="progressBarContainer">
                <h5> MERN stack </h5> 
                <ProgressBar completed={65} bgColor="#83cd29"/>
            </div>
            <div className="progressBarContainer">
                <h5> LAMP stack </h5> 
                <ProgressBar completed={99} bgColor="#000000"/>
            </div>

            <div className="progressBarContainer">
                <h5> Docker </h5> 
                <ProgressBar completed={75} bgColor="#1a98f2"/>
            </div>
        </>
    )
}
export default ProgressBarCollection;