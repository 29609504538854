

import ReviewSlider from "../../../SliderComponents/ReviewSlider/reviewSlider";

import "./feedbackSection.css";

const FeedbackSection = () => {
    
    return (
        <section className="feedback section">
            <div className="container displayFlex flex-wrap flex-lg-row-reverse align-items-start justifyContentBetween justify-content-md-center justify-content-lg-between">
                <div className="feedback_header section_header col-12 col-lg-5">
                    <span className="lineTitle">Feedback From</span>
                    <h2 className="title">
                        
                        <span className="ternary">My Clients</span>
                       
                    </h2>
                    <p className="text">
                        These are some of the feedbacks that I've manually collected from my clients. I'm always happy to recieve your feedbacks that will help us update and upgarde more in my services.
                    </p>
                    <a className="btn" href="/faq">Consult now</a>
                </div>
                <div className="wrapper col-lg-6 col-12">
                    <ul className="feedback_slider displayFlex flex-wrap" style={{marginLeft:-30,marginRight:30}}>
                        <ReviewSlider/>
                    </ul>
                </div>
            </div> 
        </section>
    )
}
export default FeedbackSection;