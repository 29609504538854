import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../ConfigurationSystem/firebase";
import {Link} from "react-router-dom";

import PaginatedItems from "./ContentType0/paginatedItems";
import OptionSection from "../../Blogs/BlogComponents/OptionSection/optionSection";
import SpinnerSvg from "../../ScreenLoader/screenLoader";


const ImageDataFetcher = ({ contentType, collectionName }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const blogCollectionRef = collection(db, collectionName);

    useEffect(() => {
        const getData = async () => {
            const data = await getDocs(blogCollectionRef);
            await setData(data.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
            setLoading(false);
        }
        getData();
    }, [])


    if (loading) { return <SpinnerSvg/> }
    else {
        const stringCutter = (str) => {
            if (str) {
                const initial = str.split(" ");
                const mid = initial.slice(0, 20);
                const final = mid.join(" ");
                return final;
            }
        }

        //Blog returners

        if (collectionName === "Blogs") {
            if (contentType === 0) {
                return (
                    <div className="container blogContainer-blogs">
                        <div className="row">
                            <div className="col-lg-8 col-12">
                                <div className="row">
                                    <PaginatedItems data={data} itemsPerPage={6} isBlogs={true}/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <OptionSection />
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else if (collectionName === "Services") {
            if (contentType === 1) {
                return (
                    <>
                        {data.map((ind, i) => (
                            <li className="services_list-item col-12 col-md-6 col-xxl-4" data-aos="fade-up" data-order="1" style={{ marginTop: 30 }}>
                                <div className="wrapper displayFlex flex-column align-items-start justifyContentBetween">
                                    <span className="number">0{i + 1}</span>
                                    <h4 className="title">{ind.Title}</h4>
                                    <p className="description">
                                        {stringCutter(ind.MiniStart)} 
                                    </p>
                                    <a className="link link-arrow" href={`/services/${ind.id}`}>
                                        Details
                                        <i className="icon-arrow_right"></i>
                                    </a>
                                </div>
                            </li>
                        ))}
                    </>
                )
            }

        }
        else if (collectionName === "Projects") {
            if (contentType === 0) {
                return (
                    <>
                        {data.map(project => (
                            <li className="projects_list-item col-12 col-md-6" data-order="2">
                                <div className="wrapper displayFlex flex-wrap justifyContentBetween">
                                    <div className="img-wrapper" data-aos="zoom-in-right" data-aos-duration="550" data-aos-once="true">
                                        <picture>
                                            <source
                                            

                                                data-srcset={`https://firebasestorage.googleapis.com/v0/b/bikal-cv.appspot.com/o/ProjectsCovers%2F${project.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23`}
                                                srcset={`https://firebasestorage.googleapis.com/v0/b/bikal-cv.appspot.com/o/ProjectsCovers%2F${project.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23`}
                                                type="image/webp"
                                            />
                                            <img
                                                className="projects_list-item_img lazy"
                                                data-src={`https://firebasestorage.googleapis.com/v0/b/bikal-cv.appspot.com/o/ProjectsCovers%2F${project.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23`}
                                                src={`https://firebasestorage.googleapis.com/v0/b/bikal-cv.appspot.com/o/ProjectsCovers%2F${project.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23`}
                                                alt=""
                                            />
                                        </picture>
                                    </div>
                                    <div className="text-wrapper displayFlex flex-column justifyContentBetween">
                                        <h3 className="projects_list-item_title" data-aos="fade-in" data-aos-duration="500" data-aos-once="true">
                                            {project.Title}
                                            <span
                                                className="divider divider--line"
                                                data-aos="slide-right"
                                                data-aos-duration="500"
                                                data-aos-once="true"
                                            ></span>
                                        </h3>

                                        <hr />
                                        <div className="projects_list-item_info displayFlex flex-wrap  justifyContentBetween">
                                            <span
                                                className="location displayFlex "
                                                data-aos="fade-in"
                                                data-aos-duration="500"
                                                data-aos-delay="50"
                                                data-aos-once="true"
                                            >
                                                <i className="icon-location icon"></i>
                                                {project.location}
                                            </span>

                                            <Link to={`/projects/${project.id}`}
                                                className="link link-arrow"
                                                data-aos="fade-in"
                                                data-aos-duration="500"
                                                data-aos-delay="50"
                                                data-aos-once="true"
                                            >
                                                Details
                                                <i className="icon-arrow_right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}

                    </>
                )
            }
        }

        else if (collectionName === "Certificates") {
            if (contentType === 0) {
                return (
                    <>
                        {data.map(certificate => (
                            <li className="certificates_list-item col-12 col-md-6" data-order="2">
                                <div className="wrapper displayFlex flex-wrap justifyContentBetween">
                                    <div className="img-wrapper" data-aos="zoom-in-right" data-aos-duration="550" data-aos-once="true">
                                    <picture>
                                            <source
                                                data-srcset={`https://firebasestorage.googleapis.com/v0/b/bikal-cv.appspot.com/o/CertificatesCovers%2F${certificate.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23`}
                                                srcset={`https://firebasestorage.googleapis.com/v0/b/bikal-cv.appspot.com/o/CertificatesCovers%2F${certificate.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23`}
                                                type="image/webp"
                                            />
                                            <img
                                                className="certificates_list-item_img lazy"
                                                data-src={`https://firebasestorage.googleapis.com/v0/b/bikal-cv.appspot.com/o/CertificatesCovers%2F${certificate.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23`}
                                                src={`https://firebasestorage.googleapis.com/v0/b/bikal-cv.appspot.com/o/CertificatesCovers%2F${certificate.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23`}
                                                alt=""
                                            />
                                        </picture>
                                    </div>
                                    <div className="text-wrapper displayFlex flex-column justifyContentBetween">
                                        <h3 className="certificates_list-item_title" data-aos="fade-in" data-aos-duration="500" data-aos-once="true">
                                            {certificate.Title}
                                            <span
                                                className="divider divider--line"
                                                data-aos="slide-right"
                                                data-aos-duration="500"
                                                data-aos-once="true"
                                            ></span>
                                        </h3>

                                        <hr />
                                        <div className="certificates_list-item_info displayFlex flex-wrap  justifyContentBetween">
                                            <span
                                                className="certified displayFlex "
                                                data-aos="fade-in"
                                                data-aos-duration="500"
                                                data-aos-delay="50"
                                                data-aos-once="true"
                                            >
                                                <i class="fa fa-certificate" aria-hidden="true"></i>
                                                {certificate.certified}
                                            </span>

                                            <Link to={`/certificates/${certificate.id}`}
                                                className="link link-arrow"
                                                data-aos="fade-in"
                                                data-aos-duration="500"
                                                data-aos-delay="50"
                                                data-aos-once="true"
                                            >
                                                Details
                                                <i className="icon-arrow_right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}

                    </>
                )
            }
        }
    }
}

export default ImageDataFetcher;