import "./404page.css";

const ErrorPage=()=>{

    return(
        <main className="errorPageDisplay d-lg-flex align-items-start">
            <div className="container displayFlex flex-wrap justifyContentCenter ">
                <span className="errorPageDisplay_number col-12 col-md-auto">404</span>
                <div className="errorPageDisplay_message col-12 col-md-auto displayFlex flex-column align-items-start">
                    <div className="errorPageDisplay_message-header">
                        <span className="lineTitle">Oops!</span>
                        <h3 className="title">Page not Found</h3>
                    </div>
                    <p className="errorPageDisplay_message-text">We are sorry, the page you requested cannot be found</p>
                    <a className="btn" href="/">Home page</a>
                </div>
            </div>
        </main>
    )
}
export default ErrorPage;