import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../ConfigurationSystem/firebase";

import SpinnerSvg from "../../ScreenLoader/screenLoader";
import FAQContents from "./ContactUsSection/FAQContents/faqContents";

import "./faqSection.css";

const FAQSection = () => {
    const [loading,setLoading]=useState(true);
    const [data,setData]=useState([]);

    const faqCollectionRef=collection(db,"FAQs");
    
    useEffect(() => {
        const getData = async () => {
            const data = await getDocs(faqCollectionRef);
            await setData(data.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
            setLoading(false);
        }
        getData();
    }, [])

    return (
        <section className="faq section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 faq_header section_header col-lg-12 col-xl-auto">
                        <span className="lineTitle">Dealing with your worries</span>
                        <h2 className="title">
                            If Your Question Is Not Here
                            <span className="ternary"> Reach Me</span>
                        </h2>
                        <p className="text">
                            If the answer to your questions are not present feel free to check our handles and contact me.
                        </p>
                        <div className="wrapper">
                            <a className="btn" href="/contacts">Contact Me</a>
                        </div>
                    </div>


                    <div className="col-lg-6 col-md-6 col-12 sectionAccordion faq_sectionAccordion">
                        {loading ? <SpinnerSvg/> : <FAQContents data={data}/>} 
                    </div>
                </div>
            </div>
        </section>
    )
}
export default FAQSection;