import { useEffect } from "react";

import Aos from "aos";
import ImageDataFetcher from "../../../CommonComponents/ImageDataFetcher/imageDataFetcher";

import "aos/dist/aos.css";
import "./servicesMini.css";

const ServicesMini = () => {

    useEffect(()=>{
        Aos.init({duration:800,once:true})
    },[]);


    return (
        <section className="services section">
            <div className="container">
                <div className="services_header section_header">
                    <span className="lineTitle">Magics I Do</span>
                    <h2 className="title" data-aos="fade-right" data-aos-duration="500">Services</h2>
                </div>
                <ul className="services_list row g-0">
                    <ImageDataFetcher collectionName={"Services"} contentType={1}/>
                </ul>
            </div>
        </section>

    )
}
export default ServicesMini;